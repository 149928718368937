<template>
  <v-container v-if="visit" class="ma-1 pa-0">
    <v-row align="center">
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead class="header"><tr>
              <!-- <th class="text-left">id</th> -->
              <th class="text-left"></th>
              <th class="text-left">{{tLabel('Name')}}</th>
              <th class="text-left">{{tLabel('Surname')}}</th>
              <th class="text-left">{{tLabel('Identity doc number')}}</th>
              <th class="text-left">{{tLabel('Driver license')}}</th>
              <th class="text-left">{{tLabel('Badge')}}</th>
              <th class="text-left">{{tLabel('Status')}}</th>
            </tr></thead>
            <tbody><tr>          
              <!-- <td>{{ visit.id }}</td> -->
              <td><v-icon color="primary">{{iconMap["PER"]}}</v-icon></td>
              <td>{{ visit.name }}</td>
              <td>{{ visit.surname }}</td>
              <td>{{ visit.identityDocNumber }}</td>
              <td>{{ visit.driverLicense }}</td>
              <td>{{ visit.badge }}</td>
              <td>
                <EnumVisualization :value="visit.status" :enums="visitStatus" :enumsMap="visitStatusMap"/>        
              </td>        
            </tr></tbody>
          </template>
        </v-simple-table>
      </v-col>   
    </v-row>
  </v-container>
</template>

<script>
import YardEnumMixin from "@/mixins/yard/EnumMixin";
import EnumVisualization from '@/components/yard/EnumVisualization.vue';

export default {
  name: "PersonVisitViewPanelLite",
  props: {
    visit: {
      type: Object,
      required: true      
    },
  },
  data() {
    return {

    };
  },

  async mounted() {

  },
  mixins: [YardEnumMixin],
  components: {
    EnumVisualization
  },
};
</script>