<template>

  <v-container v-if="visit" class="ma-1 pa-0">
    <v-row align="center">
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead class="header"><tr>
              <!-- <th class="text-left">id</th> -->
              <th class="text-left"></th>
              <th class="text-left">{{tLabel('Unit Code')}}</th>
              <th class="text-left">{{tLabel('Owner')}}</th>
              <th class="text-left">{{tLabel('Status')}}</th>
              <th class="text-left">{{tLabel('Unit sys. subtype')}}</th>
              <th class="text-left">{{tLabel('Unit type code')}}</th>
              <th class="text-left">{{tLabel('ISO size type')}}</th>
              <th class="text-left">{{tLabel('Goods')}}</th>
              <th class="text-left">{{tLabel('Quantity')}}</th>
              <th class="text-left">{{tLabel('Gross weight')}}</th>
            </tr></thead>
            <tbody><tr>          
              <!-- <td>{{ visit.id }}</td> -->
              <td><v-icon color="primary">{{iconMap["UNT"]}}</v-icon></td>
              <td>{{ visit.objectCode }}</td>
              <td>{{ visit.owner }}</td>
              <td>
                <EnumVisualization :value="visit.status" :enums="visitStatus" :enumsMap="visitStatusMap"/>        
              </td>    
              <td>{{unitSysSubtypesMap[visit.sysSubtype]}}</td>  
              <td>{{visit.unitTypeCode}}</td>  
              <td>{{visit.isoSizeType}}</td>  
              <td>{{visit.goods}}</td> 
              <td>{{visit.qty}}</td>    
              <td>{{visit.grossweight}}</td> 
            </tr></tbody>
          </template>
        </v-simple-table>
      </v-col>   
    </v-row>
  </v-container>
</template>

<script>
import YardEnumMixin from "../../mixins/yard/EnumMixin";
import EnumVisualization from '@/components/yard/EnumVisualization.vue';

export default {
  name: "UnitVisitViewPanelLite",
  props: {
    visit: {
      type: Object,
      required: true      
    },
  },
  data() {
    return {

    };
  },

  async mounted() {

  },
  mixins: [YardEnumMixin],
  components: {
    EnumVisualization
  },
};
</script>