<template>
  <div>
    <v-container fill-height fluid>
      <v-row align="center" justify="center" v-if="standalone == true">
        <v-col cols="1">
          <v-btn color="warning" @click="back">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="11" class="text-h6 font-weight-regular">
          Visualizzazione dettagli
          <!--{{visitId}} {{visitSysType}}-->
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12">
          <UnitVisitViewPanel v-if="visitSysType === 'UNT'" :visitId="visitId" @visitEditStatusChanged="onVisitEditStatusChange" />
          <GoodsVisitViewPanel v-else-if="visitSysType === 'GOO'" :visitId="visitId" @visitEditStatusChanged="onVisitEditStatusChange" />
          <RoroVisitViewPanel v-else-if="visitSysType === 'RRO'" :visitId="visitId" @visitEditStatusChanged="onVisitEditStatusChange" />
          <PersonVisitViewPanel v-else-if="visitSysType === 'PER'" :visitId="visitId" @visitEditStatusChanged="onVisitEditStatusChange" />
          <!-- TODO inserire view panel per parent -->
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12">
          <v-card>
            <v-card-title class="text-uppercase font-weight-bold">
              <span
                ><span class="mx-2 px-2">{{ tLabel("Handlings") }}</span></span
              >
            </v-card-title>
            <v-divider class="mx-4 warning"></v-divider>
            <v-card-text>
              <v-expansion-panels v-model="expansionPanel">
                <v-expansion-panel v-for="handling in handlings" :key="handling.id">
                  <HandlingExpansionPanelContent
                    :handlingId="handling.id"
                    visitHidden
                    :primaryVisitClickDisabled="handling.primaryVisitId === visitId"
                    :carrierVisitClickDisabled="handling.carrierVisitId === visitId"
                  />
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="confirmDialogVisible" persistent width="600">
      <v-card>
        <v-card-title class="text-h5 text-uppercase panelheader">
          {{ tLabel("Confirm") }}
        </v-card-title>
        <v-card-text class="text-h6">
          {{ tLabel("Le modifiche non sono state salvate.") }}
          <br/>
          {{ tLabel("Proseguire senza salvare?") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined @click="confirmDialogVisible = false">{{ tLabel("No") }}</v-btn>
          <v-btn class="primary" @click="$router.go(-1)">{{ tLabel("Si") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import YardEnumMixin from "../../../mixins/yard/EnumMixin";
import UnitVisitViewPanel from "@/components/yard/UnitVisitViewPanel";
import GoodsVisitViewPanel from "@/components/yard/GoodsVisitViewPanel";
import RoroVisitViewPanel from "@/components/yard/RoroVisitViewPanel";
import PersonVisitViewPanel from "@/components/yard/PersonVisitViewPanel";
import HandlingExpansionPanelContent from "@/components/yard/HandlingExpansionPanelContent";

export default {
  name: "VisitDetails",
  data() {
    return {
      expansionPanel: [0],
      model: 0,
      // visit: {},
      handlings: [],
      visitEditing: false,
      confirmDialogVisible: false
    };
  },
  props: {
    visitId: { required: true },
    visitSysType: { required: true },
    standalone: {
      default: () => {
        return true;
      },
    },
  },
  emits: ["visitEditStatusChanged"],
  components: {
    UnitVisitViewPanel,
    GoodsVisitViewPanel,
    RoroVisitViewPanel,
    PersonVisitViewPanel,
    HandlingExpansionPanelContent,
  },
  mixins: [YardEnumMixin],
  async mounted() {
    await this.fetchData();
  },
  watch: {
    visitId: async function () {
      await this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      try {
        console.log("VisitDetails fetchData visitId, visitSysType", this.visitId, this.visitSysType);
        // this.visit = await this.$api.yardData.getVisitById(this.visitId);
        // console.log("VisitDetails fetchData visit", this.visit);
        this.handlings = await this.$api.yardData.getHandlingsByVisitId(this.visitId);
        console.log("VisitDetails fetchData handlings", this.handlings);
      } catch (e) {
        console.log(e);
      }
    },
    onVisitEditStatusChange(visitId, status) {
      console.log("VisitDetails onVisitEditStatusChange --------------------- ", visitId, status);
      this.visitEditing = false;
      if (status && status === "IN_PROGRESS") {
        this.visitEditing = true;
      }
      this.$emit("visitEditStatusChanged", visitId, status);
    },
    back() {
      if (!this.visitEditing) {
        this.$router.go(-1);
      } else {
        this.confirmDialogVisible = true;
      }
    },
  },
};
</script>
