<template>
  <v-container fluid v-if="visit" class="pa-0 ma-0">
    <v-row align="center" v-if="visit.primaryFlg && visit.primaryFlg==='Y'">
      <v-col cols="12"> 
        <v-card>
          <v-app-bar height="30" flat color="primary">
            <span class="white--text text-uppercase text-subtitle-1">{{tLabel("Linked visits")}}</span>
          </v-app-bar>
          <v-card-text>
            <div v-for="v in linkedToPrimaryVisits" :key="v.id" @click="goToVisitDetails(v.id, v.sysType)" class="py-0 w-1/4 pointer">
              <UnitVisitViewPanelLite v-if="v.sysType === 'UNT'" :visit="v"/>
              <GoodsVisitViewPanelLite v-else-if="v.sysType === 'GOO'" :visit="v"/>
              <RoroVisitViewPanelLite v-else-if="v.sysType === 'RRO'" :visit="v"/>
              <PersonVisitViewPanelLite v-else-if="v.sysType === 'PER'" :visit="v" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" v-else-if="visit.primaryVisitId">
      <v-col cols="12"> 
        <v-card>
          <v-app-bar height="30" flat color="primary">
            <span class="white--text text-uppercase text-subtitle-1">{{tLabel("Primary visit")}}</span>
          </v-app-bar>
          <v-card-text>
            <div @click="goToVisitDetails(visit.primaryVisit.id, visit.primaryVisit.sysType)" class="py-0 w-1/4 pointer">
              <UnitVisitViewPanelLite v-if="visit.primaryVisit.sysType === 'UNT'" :visit="visit.primaryVisit"/>
              <GoodsVisitViewPanelLite v-else-if="visit.primaryVisit.sysType === 'GOO'" :visit="visit.primaryVisit"/>
              <RoroVisitViewPanelLite v-else-if="visit.primaryVisit.sysType === 'RRO'" :visit="visit.primaryVisit"/>
              <PersonVisitViewPanelLite v-else-if="visit.primaryVisit.sysType === 'PER'" :visit="visit.primaryVisit"/>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container> 
</template>

<script>
import UnitVisitViewPanelLite from '@/components/yard/UnitVisitViewPanelLite';
import GoodsVisitViewPanelLite from '@/components/yard/GoodsVisitViewPanelLite';
import RoroVisitViewPanelLite from '@/components/yard/RoroVisitViewPanelLite';
import PersonVisitViewPanelLite from '@/components/yard/PersonVisitViewPanelLite';
import YardEnumMixin from "@/mixins/yard/EnumMixin";

export default {
  name: "VisitPrimaryViewPanel",
  props: {
    visit: {
      type: Object,
      required: true      
    },
  },
  data() {
    return {
      primaryVisit: {},
      linkedToPrimaryVisits: []
    };
  },
  computed: {

  },
  watch: {
    visit: async function () {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {   
    async fetchData() {
      try { 
        console.log("primary visit -------->", this.visit);
        console.log("primary prova logic -------->", (this.visit.primaryFlg && this.visit.primaryFlg==='Y') );
        if (this.visit.primaryFlg && this.visit.primaryFlg==='Y') {
          // visita primary
          this.linkedToPrimaryVisits = await this.$api.yardData.getVisitsByPrimaryId(this.visit.id);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  mixins: [YardEnumMixin],
  components: {
    UnitVisitViewPanelLite,
    GoodsVisitViewPanelLite,
    RoroVisitViewPanelLite,
    PersonVisitViewPanelLite
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>