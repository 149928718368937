<template>
  <v-card>
    <v-toolbar flat>
      <v-icon large>
        {{ iconMap[visit.sysType] }}
      </v-icon>
      <span class="text-uppercase text-h5 mx-2 px-2">
        {{ tLabel(sysTypesMap[visit.sysType]) }}
      </span>
      <v-spacer />
      <v-btn color="primary" class="mx-2" v-if="!editMode" @click="editVisit"><v-icon>mdi-pencil</v-icon></v-btn>
      <v-btn outlined class="mx-2" v-if="editMode" @click="discardChanges"><v-icon>mdi-close</v-icon></v-btn>
      <v-btn color="success" class="mx-2" v-if="editMode" @click="saveChanges"><v-icon>mdi-content-save</v-icon></v-btn>      
    </v-toolbar>
    <v-divider class="mx-4 warning"></v-divider>
    <v-card-text>
      <v-form ref="editForm">
        <v-container v-if="visit">
          <!-- <v-row align="center">
              <v-col cols="12">
                {{visit}}       
              </v-col>
            </v-row> -->
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :value="visit.name" :label="tLabel('Name')" />
              <v-text-field height="75" v-else :label="tLabel('Name')" v-model="visit.name" background-color="header" @input="(val) => (visit.name = val ? val.toUpperCase() : null)"/>
            </v-col>
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :value="visit.surname" :label="tLabel('Surname')" />
              <v-text-field height="75" v-else :label="tLabel('Surname')" v-model="visit.surname" background-color="header" @input="(val) => (visit.surname = val ? val.toUpperCase() : null)"/>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent :label="tLabel('Status')">
                <template v-slot:content>
                  <EnumVisualization :value="visit.status" :enums="visitStatus" :enumsMap="visitStatusMap" />
                </template>
              </LabeledContent>
            </v-col>
            <v-col cols="4">
              <LabeledContent :label="tLabel('Position Status')">
                <template v-slot:content>
                  <EnumVisualization :value="visit.positionStatus" :enums="positionStatus" :enumsMap="positionStatusMap" />
                </template>
              </LabeledContent>
            </v-col>
            <v-col cols="4" v-if="visit.positionVisitId">
              <LabeledContent :value="visit.positionVisitObjectCode" :label="tLabel('Position')">
                <template #content="slotProps">
                  <v-chip label color="primary" @click="goToVisitDetails(visit.positionVisitId, visit.positionVisitSysType)">
                    <span class="white--text text-h6">
                      {{ slotProps.data }}
                    </span>
                  </v-chip>
                </template>
              </LabeledContent>
            </v-col>
            <v-col cols="4" v-else>
              <v-text-field height="75" v-if="editMode && visit.positionType==='Y'" :label="tLabel('Position')" v-model="visit.positionCode" background-color="header" @input="(val) => (visit.positionCode = val ? val.toUpperCase() : null)"/>
              <LabeledContent v-else :value="visit.positionCode" :label="tLabel('Position')" />        
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent :value="personSysSubtypesMap[visit.sysSubtype]" :label="tLabel('Type')" />
            </v-col>
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :value="visit.owner" :label="tLabel('Company')" />
              <v-select v-else height="75" :label="tLabel('Company')" v-model="visit.ownerCompanyId" :items="carriers" item-value="id" item-text="code" background-color="header"/>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :value="visit.identityDocNumber" :label="tLabel('Identity doc number')" />
              <v-text-field height="75" v-else :label="tLabel('Identity doc number')" v-model="visit.identityDocNumber" background-color="header" @input="(val) => (visit.identityDocNumber = val ? val.toUpperCase() : null)"/>
            </v-col>
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :value="visit.driverLicense" :label="tLabel('Driver license')" />
              <v-text-field height="75" v-else :label="tLabel('Driver license')" v-model="visit.driverLicense" background-color="header" @input="(val) => (visit.driverLicense = val ? val.toUpperCase() : null)"/>
            </v-col>
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :value="visit.badge" :label="tLabel('Badge')" />
              <v-text-field height="75" v-else :label="tLabel('Badge')" v-model="visit.badge" background-color="header" @input="(val) => (visit.badge = val ? val.toUpperCase() : null)"/>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <LabeledContent v-if="!editMode" :label="tLabel('Visit reason')" :value="visit.visitReason" />
              <v-text-field height="75" v-else :label="tLabel('Visit reason')" v-model="visit.visitReason" background-color="header" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <LabeledContent v-if="!editMode" :label="tLabel('Remarks')" :value="visit.remarks" />
              <v-textarea v-else :label="tLabel('Remarks')" v-model="visit.remarks" counter maxlength="255" background-color="header" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <VisitPrimaryViewPanel :visit="visit" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import YardEnumMixin from "../../mixins/yard/EnumMixin";
import FormatsMixins from "../../mixins/FormatsMixins";
import EnumVisualization from "@/components/yard/EnumVisualization.vue";
import LabeledContent from "@/components/yard/LabeledContent.vue";
import VisitPrimaryViewPanel from "@/components/yard/VisitPrimaryViewPanel.vue";

export default {
  name: "PersonVisitViewPanel",
  props: {
    visitId: {
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      visit: {},
      carriers: [],
    };
  },
  emits: ["visitEditStatusChanged"],
  computed: {},
  async mounted() {
    await this.fetchData();
  },
  watch: {
    visitId: async function () {
      await this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      try {
        this.carriers = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/carriers");
        this.visit = await this.$api.yardData.getVisitById(this.visitId);
        console.log("PersonVisitViewPanel fetchData visit", this.visit);
      } catch (e) {
        console.log(e);
      }
    },
    editVisit() {
      this.$emit("visitEditStatusChanged", this.visit.id, "IN_PROGRESS");
      this.editMode = true;
    },
    async saveChanges() {
      const valid = this.$refs.editForm.validate();
      if (!valid) {
        return;
      }
      console.log("save visit changes", this.visit);
      var ret = await this.$api.yardData.updateVisit(this.visit, "PER");
      console.log("save visit changes ret ", ret);
      this.$emit("visitEditStatusChanged", this.visit.id, "SAVED");
      await this.fetchData();
      this.editMode = false;
    },
    async discardChanges() {
      console.log("discard visit changes", this.visit);
      this.$emit("visitEditStatusChanged", this.visit.id, "DISCARDED");
      await this.fetchData();
      this.editMode = false;
    },
  },
  mixins: [YardEnumMixin, FormatsMixins],
  components: {
    EnumVisualization,
    LabeledContent,
    VisitPrimaryViewPanel,
  },
};
</script>
