<template>
  <v-card>
    <v-card-title class="text-uppercase font-weight-light grey lighten-5">
      <v-icon large class="mr-2">mdi-view-week-outline</v-icon>{{ tLabel("Units") }}
      <v-spacer />
      <v-btn depressed :outlined="page!=='INFO'" color="primary" class="mx-2" @click="page='INFO'">
        <v-icon class="mr-2">mdi-information-outline</v-icon>{{ tLabel("Info") }}
      </v-btn>
      <v-btn depressed :outlined="page!=='ITINERARY'" color="primary" class="mx-2" @click="page='ITINERARY'">
        <v-icon class="mr-2">mdi-map-marker-path</v-icon>{{ tLabel("Departure") + " / " + tLabel("Destination") }}
      </v-btn>
      <v-btn color="primary" class="mx-2" v-if="!editMode" @click="editVisit"><v-icon>mdi-pencil</v-icon></v-btn>
      <v-btn outlined class="mx-2" v-if="editMode" @click="discardChanges"><v-icon>mdi-close</v-icon></v-btn>
      <v-btn color="success" class="mx-2" v-if="editMode" @click="saveChanges"><v-icon>mdi-content-save</v-icon></v-btn>     
    </v-card-title>
    <v-card-text v-show="page === 'INFO'">
      <v-form ref="editForm">
        <v-container v-if="visit">
          <!-- <v-row align="center">
            <v-col cols="12">
              {{visit}}       
            </v-col>
          </v-row> -->
          <!-- <v-row align="center">
              <v-col cols="12">
                <UnitVisitViewPanelLite :visit="visit"/>
              </v-col>
            </v-row> -->
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :value="visit.objectCode" :label="tLabel('Unit Code')" />
              <v-text-field height="75" v-else :label="tLabel('Unit Code')" v-model="visit.objectCode" background-color="header" @input="(val) => (visit.objectCode = val ? val.toUpperCase() : null)" />
            </v-col>

            <v-col cols="2" v-if="editMode">
              <v-checkbox label="Forza UNIT" v-model="skipCheckDigitUtiId" />
            </v-col>

            <v-col cols="4">
              <LabeledContent v-if="!editMode" :value="visit.owner" :label="tLabel('Owner')" />
              <v-autocomplete height="75" v-else :label="tLabelRequired('Owner')" v-model="visit.ownerCompanyId" background-color="header" 
               :items="owners" item-value="id" item-text="code"/>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent :label="tLabel('Status')">
                <template v-slot:content>
                  <EnumVisualization :value="visit.status" :enums="visitStatus" :enumsMap="visitStatusMap" />
                </template>
              </LabeledContent>
            </v-col>
            <v-col cols="4">
              <LabeledContent :label="tLabel('Position Status')">
                <template v-slot:content>
                  <EnumVisualization :value="visit.positionStatus" :enums="positionStatus" :enumsMap="positionStatusMap" />
                </template>
              </LabeledContent>
            </v-col>
            <v-col cols="4" v-if="visit.positionVisitId">
              <LabeledContent :value="visit.positionVisitObjectCode" :label="tLabel('Position')">
                <template #content="slotProps">
                  <v-chip label color="primary" @click="goToVisitDetails(visit.positionVisitId, visit.positionVisitSysType)">
                    <span class="white--text text-h6">
                      {{ slotProps.data }}
                    </span>
                  </v-chip>
                </template>
              </LabeledContent>
            </v-col>
            <v-col cols="4" v-else>
              <v-text-field height="75" v-if="editMode && visit.positionType === 'Y'" :label="tLabel('Position')" v-model="visit.positionCode" background-color="header" @input="(val) => (visit.positionCode = val ? val.toUpperCase() : null)"/>
              <LabeledContent v-else :value="visit.positionCode" :label="tLabel('Position')" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent :value="unitSysSubtypesMap[visit.sysSubtype]" :label="tLabel('Unit sys. subtype')" />
            </v-col>
            <v-col cols="4">
              <LabeledContent :value="visit.unitTypeCode" :label="tLabel('Unit type code')" />
            </v-col>
            <v-col cols="4">
              <LabeledContent :value="visit.isoSizeType" :label="tLabel('ISO size type')" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :value="visit.goods" :label="tLabel('Goods')" />
              <v-text-field height="75" v-else :label="tLabel('Goods')" v-model="visit.goods" background-color="header" />
            </v-col>
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :value="visit.lot" :label="tLabel('Lot')" />
              <v-text-field height="75" v-else :label="tLabel('Lot')" v-model="visit.lot" background-color="header" />
            </v-col>
            <v-col cols="4">
              <LabeledContent :label="tLabel('Customs Status')">
                <template v-slot:content>
                  <EnumVisualization :value="visit.customsStatus" :enums="customsStatus" :enumsMap="customsStatusMap" />
                </template>
              </LabeledContent>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <LabeledContent v-if="!editMode" :value="visit.description" :label="tLabel('Description')" />
              <v-text-field height="75" v-else :label="tLabel('Description')" v-model="visit.description" background-color="header" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3">
              <LabeledContent :label="tLabel('Quantity')" :numericValue="visit.qty" />
            </v-col>
            <v-col cols="3">
              <LabeledContent v-if="!editMode" :label="tLabel('Gross weight')" :numericValue="visit.grossweight" />
              <v-text-field height="75" v-else :label="tLabel('Gross weight')" v-model="visit.grossweight" background-color="header" />
            </v-col>
            <v-col cols="3">
              <LabeledContent v-if="!editMode" :label="tLabel('Net weight')" :numericValue="visit.netWeight" />
              <v-text-field height="75" v-else :label="tLabel('Net weight')" v-model="visit.netWeight" background-color="header" />
            </v-col>
            <v-col cols="3">
              <LabeledContent :label="tLabel('Tare Mass')" :numericValue="visit.tareMass" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent :label="tLabel('Length')" :numericValue="visit.length" />
            </v-col>
            <v-col cols="4">
              <LabeledContent :label="tLabel('Width')" :numericValue="visit.width" />
            </v-col>
            <v-col cols="4">
              <LabeledContent :label="tLabel('Height')" :numericValue="visit.height" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :label="tLabel('Seal 1')" :value="visit.seal1" />
              <v-text-field height="75" v-else :label="tLabel('Seal 1')" v-model="visit.seal1" background-color="header" @input="(val) => (visit.seal1 = val ? val.toUpperCase() : null)"/>
            </v-col>
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :label="tLabel('Seal 2')" :value="visit.seal2" />
              <v-text-field height="75" v-else :label="tLabel('Seal 2')" v-model="visit.seal2" background-color="header" @input="(val) => (visit.seal2 = val ? val.toUpperCase() : null)"/>
            </v-col>
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :label="tLabel('Seal 3')" :value="visit.seal3" />
              <v-text-field height="75" v-else :label="tLabel('Seal 3')" v-model="visit.seal3" background-color="header" @input="(val) => (visit.seal3 = val ? val.toUpperCase() : null)"/>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :label="tLabel('Full/Empty')">
                <template v-slot:content>
                  <EnumVisualization :value="visit.fullEmpty" :enums="fullEmpties" :enumsMap="fullEmptiesMap" />
                </template>
              </LabeledContent>
              <v-select height="75" v-else :label="tLabel('Full/Empty')" v-model="visit.fullEmpty" :items="fullEmpties" background-color="header" />
            </v-col>
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :label="tLabel('Dangerous')">
                <template v-slot:content>
                  <div><v-icon v-if="visit.dangerous === true" color="warning">mdi-alert</v-icon></div>
                </template>
              </LabeledContent>
              <v-checkbox height="75" v-else :label="tLabel('Dangerous')" v-model="visit.dangerous" background-color="header" />
            </v-col>
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :label="tLabel('Cycle')" :value="visit.cycle">
              </LabeledContent>
              <v-select v-else v-model="visit.cycle" :items="cyclesList" :label="tLabel('Cycle')" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <LabeledContent v-if="!editMode" :label="tLabel('Remarks')" :value="visit.remarks" />
              <v-textarea v-else :label="tLabel('Remarks')" v-model="visit.remarks" counter maxlength="255" background-color="header" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <VisitPrimaryViewPanel :visit="visit" />
            </v-col>
          </v-row>
          <v-row v-if="visit.damages">
            <v-col cols="12">
              <VisitDamagesViewPanel :damages="visit.damages" :editMode="editMode" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-text v-show="page === 'ITINERARY'">
      <ItineraryForm dense :itineraryVisit="visit.itineraryVisit" v-if="visit.itineraryVisit" :editable="editMode"/>
    </v-card-text>
  </v-card>
</template>

<script>
import YardEnumMixin from "../../mixins/yard/EnumMixin";
import FormatsMixins from "../../mixins/FormatsMixins";
import EnumVisualization from "@/components/yard/EnumVisualization.vue";
import LabeledContent from "@/components/yard/LabeledContent.vue";
import UnitVisitViewPanelLite from "@/components/yard/UnitVisitViewPanelLite";
import VisitPrimaryViewPanel from "@/components/yard/VisitPrimaryViewPanel";
import VisitDamagesViewPanel from "@/components/yard/VisitDamagesViewPanel";
import ToastMixin from "@/mixins/ToastMixin";
import ItineraryForm from "@/components/yard/ItineraryForm";

export default {
  name: "UnitVisitViewPanel",
  props: {
    visitId: {
      required: true,
      type: Number
    },
  },
  data() {
    return {
      page: "INFO",
      visit: {},
      editMode: false,
      skipCheckDigitUtiId: false,
      owners: [],
    };
  },
  emits: ["visitEditStatusChanged"],
  computed: {},
  async mounted() {
    await this.fetchData();
  },
  watch: {
    visitId: async function () {
      await this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      try {
        this.visit = await this.$api.yardData.getVisitById(this.visitId);
        this.owners= await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/owners");
        console.log("UnitVisitViewPanel fetchData visit", this.visit);
      } catch (e) {
        console.log(e);
      }
    },
    editVisit() {
      this.$emit("visitEditStatusChanged", this.visit.id, "IN_PROGRESS");
      this.editMode = true;
    },
    async saveChanges() {
      console.log("----------------------------------- VISIT DAMAGES", this.visit.damages);
      const valid = this.$refs.editForm.validate();
      if (!valid) {
        return;
      }
      console.log("save visit changes", this.visit);

      if (this.visit.sysSubtype === "CTR" && !this.skipCheckDigitUtiId) {
        console.log("UNIT", this.visit.objectCode);

        let verificato = this.verifycontainercode(this.visit.objectCode);
        console.log("verificato", verificato);
        if (!verificato) {
          console.log("verificato", verificato);
          this.showError("UNIT non conforme");
          return;
        }
      }

      // var ret = await this.$api.yardData.updateVisitWithDamages(this.visit, this.damages, "UNT");
      var ret = await this.$api.yardData.updateVisitWithDamages(this.visit, "UNT");
      console.log("save visit changes ret ", ret);
      this.$emit("visitEditStatusChanged", this.visit.id, "SAVED");
      await this.fetchData();
      this.editMode = false;
    },

    verifycontainercode(containerCode) {
      console.log("containerCode", containerCode);
      var valore = containerCode.toUpperCase();
      var somma = 0;
      var check = 0;
      var alfa;
      var valori;
      let nume = [10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38];
      var continuo = true;
      var posiz = 0;

      if (valore === null || valore === "") {
        return true;
      }
      if (valore.length == 11) {
        alfa = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        valori = "1234567890";
        for (let i = 0; i < 4; i++) {
          posiz = alfa.indexOf(valore.charAt(i));
          if (posiz < 0) {
            continuo = false;
            break;
          } else {
            somma = somma + nume[posiz] * (1 << i);
          }
        }
        if (continuo) {
          for (let i = 4; i < 10; i++) {
            posiz = valori.indexOf(valore.charAt(i));
            if (posiz < 0) {
              continuo = false;
              break;
            } else {
              somma = somma + (valore.charAt(i) - "0") * (1 << i);
            }
          }
        }
        if (continuo) {
          check = somma % 11;
          if (check == 10) {
            check = 0;
          }
          if (check == valore.charAt(10) - "0") {
            return true;
          }
        }
      }
      return false;
    },

    async discardChanges() {
      console.log("discard visit changes", this.visit);
      this.$emit("visitEditStatusChanged", this.visit.id, "DISCARDED");
      await this.fetchData();
      this.editMode = false;
    },
  },
  mixins: [YardEnumMixin, FormatsMixins, ToastMixin],
  components: {
    EnumVisualization,
    LabeledContent,
    UnitVisitViewPanelLite,
    VisitPrimaryViewPanel,
    VisitDamagesViewPanel,
    ItineraryForm
  },
};
</script>

<style scoped></style>
