<template>
  <div>
    <v-expansion-panel @click="onExpansionOpen">
      <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
        <v-row align="center" class="pa-0">
          <v-col cols="1" class="pa-0">
            <span>
              <v-icon :color="handling.partial === true ? 'orange darken-1' : handling.status === 'P' ? null : handling.status === 'C' ? 'error' : 'success'">
                {{ iconMap[handling.direction] }}
              </v-icon>
              <span class="mx-2 px-2">{{ handling.direction }} </span>
            </span>
          </v-col>
          <v-col cols="1" class="pa-0">
            <span>{{ tLabel(handlingStatusMap[handling.status]) }}</span>
          </v-col>
          <v-col cols="1" class="pa-0">
            <span>
              <v-icon>{{ iconMap[handling.transportType] }}</v-icon>
              <span class="mx-2 px-2">{{ tLabel(transportTypesMap[handling.transportType]) }}</span>
            </span>
          </v-col>
          <v-col cols="2" class="pa-0">
            <span>{{ handling.voyageCode }}</span>
          </v-col>
          <v-col cols="2" class="pa-0">
            <span>{{ handling.execDate ? handling.execDate : handling.plannedDate | formatDateTime }}</span>
          </v-col>
          <v-col cols="1" class="pa-0">
            <span>{{ handling.items || handling.items === 0 ? handling.items : visit.qty }}</span>
          </v-col>
          <v-col cols="2" class="pa-0">
            <span>{{ handling.weight }}</span>
          </v-col>
          <v-spacer />
          <v-col cols="2" class="pa-0">
            <v-btn color="primary" class="mx-2" v-if="!editMode" :disabled="!exspansionOpen" @click.stop="onExpansionPanelClick"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn outlined class="mx-2" v-if="editMode" @click.stop="discardChanges"><v-icon>mdi-close</v-icon></v-btn>
            <v-btn color="success" class="mx-2" v-if="editMode" @click.stop="saveChanges"><v-icon>mdi-content-save</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="editForm">
          <v-container v-if="handling">
            <v-row align="center">
              <v-col cols="4">
                <LabeledContent :label="tLabel('Direction')">
                  <template v-slot:content>
                    <EnumVisualization :value="handling.direction" :enums="directions" :enumsMap="directionsMap" />
                  </template>
                </LabeledContent>
              </v-col>
              <v-col cols="4">
                <LabeledContent :label="tLabel('Status')">
                  <template v-slot:content>
                    <EnumVisualization :value="handling.status" :enums="handlingStatus" :enumsMap="handlingStatusMap" />
                  </template>
                </LabeledContent>
              </v-col>
              <v-col cols="4">
                <LabeledContent :label="tLabel('Role')">
                  <template v-slot:content>
                    <EnumVisualization :value="handling.visitRole" :enums="roles" :enumsMap="rolesMap" />
                  </template>
                </LabeledContent>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="4">
                <LabeledContent :label="tLabel('Order')" :value="handling.orderCode" />
              </v-col>
              <v-col cols="4">
                <LabeledContent :label="tLabel('Planned Date / Time')" :datetimeValue="handling.plannedDate" />
              </v-col>
              <v-col cols="4">
                <LabeledContent v-if="!editMode" :label="tLabel('Date / time')" :datetimeValue="handling.execDate" />
                <DateTimeAdapter v-else :label="tLabel('Date / time')" v-model="handling.execDate" textFieldHeight="75" textFieldBackgroundColor="header" />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="4">
                <LabeledContent :label="tLabel('Items')" :numericValue="(handling.items || handling.items === 0) ? new Number(handling.items) : visit.qty" />
              </v-col>
              <v-col cols="4">
                <LabeledContent :label="tLabel('Weight')" :numericValue="handling.weight" />
              </v-col>
              <v-col cols="4">
                <LabeledContent :label="tLabel('Partial')">
                  <template v-slot:content>
                    <div><v-icon v-if="handling.partial === true" color="success">mdi-check</v-icon></div>
                  </template>
                </LabeledContent>
              </v-col>
            </v-row>

            <v-row align="center" v-if="!visitHidden || visitHidden === false">
              <v-col cols="12">
                <v-card>
                  <v-app-bar height="30" flat color="primary">
                    <span class="white--text text-uppercase text-subtitle-1">{{ tLabel("Visit") }}</span>
                  </v-app-bar>
                  <v-card-text>
                    <div v-if="visitClickDisabled && visitClickDisabled === true">
                      <UnitVisitViewPanelLite v-if="visit.sysType === 'UNT'" :visit="visit" />
                      <GoodsVisitViewPanelLite v-else-if="visit.sysType === 'GOO'" :visit="visit" />
                      <RoroVisitViewPanelLite v-else-if="visit.sysType === 'RRO'" :visit="visit" />
                      <PersonVisitViewPanelLite v-else-if="visit.sysType === 'PER'" :visit="visit" />
                    </div>
                    <div v-else @click="goToVisitDetails(visit.id, visit.sysType)" class="py-0 w-1/4 pointer">
                      <UnitVisitViewPanelLite v-if="visit.sysType === 'UNT'" :visit="visit" />
                      <GoodsVisitViewPanelLite v-else-if="visit.sysType === 'GOO'" :visit="visit" />
                      <RoroVisitViewPanelLite v-else-if="visit.sysType === 'RRO'" :visit="visit" />
                      <PersonVisitViewPanelLite v-else-if="visit.sysType === 'PER'" :visit="visit" />
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row align="center" v-if="handling.primaryVisitId">
              <v-col cols="12">
                <v-card>
                  <v-app-bar height="30" flat color="primary">
                    <span class="white--text text-uppercase text-subtitle-1">{{ tLabel("Primary visit") }}</span>
                  </v-app-bar>
                  <v-card-text>
                    <div v-if="primaryVisitClickDisabled && primaryVisitClickDisabled === true">
                      <UnitVisitViewPanelLite v-if="primaryVisit.sysType === 'UNT'" :visit="primaryVisit" />
                      <GoodsVisitViewPanelLite v-else-if="primaryVisit.sysType === 'GOO'" :visit="primaryVisit" />
                      <RoroVisitViewPanelLite v-else-if="primaryVisit.sysType === 'RRO'" :visit="primaryVisit" />
                      <PersonVisitViewPanelLite v-else-if="primaryVisit.sysType === 'PER'" :visit="primaryVisit" />
                    </div>
                    <div v-else @click="goToVisitDetails(primaryVisit.id, primaryVisit.sysType)" class="py-0 w-1/4 pointer">
                      <UnitVisitViewPanelLite v-if="primaryVisit.sysType === 'UNT'" :visit="primaryVisit" />
                      <GoodsVisitViewPanelLite v-else-if="primaryVisit.sysType === 'GOO'" :visit="primaryVisit" />
                      <RoroVisitViewPanelLite v-else-if="primaryVisit.sysType === 'RRO'" :visit="primaryVisit" />
                      <PersonVisitViewPanelLite v-else-if="primaryVisit.sysType === 'PER'" :visit="primaryVisit" />
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="4">
                <v-autocomplete
                  v-if="editMode && handling.direction === 'OUT' && handling.status === 'P'"
                  clearable
                  height="75"
                  :label="tLabel('Voyage code')"
                  :items="listTrainVoyages"
                  v-model="handlingOutTrainVoyage"
                  @change="trainVoyageChanged"
                  :item-text="voyageCodeRender"
                  return-object
                  background-color="header"
                />
                <LabeledContent v-else :label="tLabel('Voyage code')" :value="handling.voyageCode" />
              </v-col>

              <v-col cols="4">
                <LabeledContent :label="tLabel('Transport Type')" :value="tLabel(transportTypesMap[handling.transportType])" :icon="iconMap[handling.transportType]" />
                <!-- <v-select height="75" v-else :label="tLabel('Transport Type')"  v-model="handling.transportType" :items="transportTypes" background-color="header" /> -->
              </v-col>
              <v-col cols="4" v-if="!handling.carrierVisitId">
                <LabeledContent :label="tLabel('Transport Vehicle')" :value="handling.carrierCode" />
              </v-col>
            </v-row>

            <v-row align="center" v-if="handling.carrierVisitId">
              <v-col cols="12">
                <v-card>
                  <v-app-bar height="30" flat color="primary">
                    <span class="white--text text-uppercase text-subtitle-1">{{ tLabel("Transport Vehicle") }}</span>
                  </v-app-bar>
                  <v-card-text>
                    <div v-if="carrierVisitClickDisabled && carrierVisitClickDisabled === true">
                      <UnitVisitViewPanelLite v-if="carrierVisit.sysType === 'UNT'" :visit="carrierVisit" />
                      <GoodsVisitViewPanelLite v-else-if="carrierVisit.sysType === 'GOO'" :visit="carrierVisit" />
                      <RoroVisitViewPanelLite v-else-if="carrierVisit.sysType === 'RRO'" :visit="carrierVisit" />
                      <PersonVisitViewPanelLite v-else-if="carrierVisit.sysType === 'PER'" :visit="carrierVisit" />
                    </div>
                    <div v-else @click="goToVisitDetails(carrierVisit.id, carrierVisit.sysType)" class="py-0 w-1/4 pointer">
                      <UnitVisitViewPanelLite v-if="carrierVisit.sysType === 'UNT'" :visit="carrierVisit" />
                      <GoodsVisitViewPanelLite v-else-if="carrierVisit.sysType === 'GOO'" :visit="carrierVisit" />
                      <RoroVisitViewPanelLite v-else-if="carrierVisit.sysType === 'RRO'" :visit="carrierVisit" />
                      <PersonVisitViewPanelLite v-else-if="carrierVisit.sysType === 'PER'" :visit="carrierVisit" />
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="4" v-if="handling.direction === 'IN'">
                <LabeledContent v-if="!editMode" :label="tLabel('Departure Locode')" :value="departureLocode" />
                <v-autocomplete v-else :label="tLabel('Departure Locode')" clearable v-model="departureLocode" height="75" :items="locodes" background-color="header"/>
              </v-col>
              <v-col cols="4" v-if="handling.direction === 'OUT'">
                <LabeledContent v-if="!editMode" :label="tLabel('Destination Locode')" :value="destinationLocode" />
                <v-autocomplete v-else :label="tLabel('Destination Locode')" clearable v-model="destinationLocode" height="75" :items="locodes" background-color="header"/>
              </v-col>

              <v-col cols="4" v-if="handling.direction === 'IN'">
                <LabeledContent v-if="!editMode" :label="tLabel('Departure Company')" :value="departureCompany" />
                <v-autocomplete v-else :label="tLabelRequired('Departure Company')" v-model="departureCompanyId" height="75" background-color="header" 
               :items="allCompanies" item-value="id" item-text="code"/>
              </v-col>
              <v-col cols="4" v-if="handling.direction === 'OUT'">
                <LabeledContent v-if="!editMode" :label="tLabel('Destination Company')" :value="destinationCompany" />
                <v-autocomplete v-else :label="tLabelRequired('Destination Company')" v-model="destinationCompanyId" height="75" background-color="header" 
               :items="allCompanies" item-value="id" item-text="code"/>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="4">
                <LabeledContent v-if="!editMode" :label="tLabel('Pod Locode')" :value="podLocode" />
                <v-autocomplete v-else clearable :label="tLabel('Pod Locode')" v-model="podLocode" :items="locodes" height="75" background-color="header"/>                     
              </v-col>
              <v-col cols="4">
                <LabeledContent v-if="!editMode" :label="tLabel('Spod Locode')" :value="spodLocode" />
                <v-autocomplete v-else clearable :label="tLabel('Spod Locode')" v-model="spodLocode" :items="locodes" height="75" background-color="header"/>                     
              </v-col>
              <v-col cols="4">
                <LabeledContent v-if="!editMode" :label="tLabel('Pol Locode')" :value="polLocode" />
                <v-autocomplete v-else clearable :label="tLabel('Pol Locode')" v-model="polLocode" :items="locodes" height="75" background-color="header"/>                     
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="12">
                <LabeledContent :label="tLabel('Remarks')" :value="handling.remarks" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script>
import moment from "moment";
import YardEnumMixin from "../../mixins/yard/EnumMixin";
import DateTimeAdapter from "@/components/anagrafiche/DateTimeAdapter";
import EnumVisualization from "@/components/yard/EnumVisualization.vue";
import LabeledContent from "@/components/yard/LabeledContent.vue";
import UnitVisitViewPanelLite from "@/components/yard/UnitVisitViewPanelLite";
import GoodsVisitViewPanelLite from "@/components/yard/GoodsVisitViewPanelLite";
import RoroVisitViewPanelLite from "@/components/yard/RoroVisitViewPanelLite";
import PersonVisitViewPanelLite from "@/components/yard/PersonVisitViewPanelLite";

export default {
  name: "HandlingExpansionPanelContent",
  props: {
    handlingId: {
      type: Number,
      required: true,
    },
    visitHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
    visitClickDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    primaryVisitClickDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    carrierVisitClickDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      handling: {},
      visit: {},
      primaryVisit: {},
      carrierVisit: {},
      editMode: false,
      locodes: null,
      departureLocode: null,
      destinationLocode: null,
      podLocode: null,
      spodLocode: null,
      polLocode: null,
      departureCompany: null,
      destinationCompany: null,
      departureCompanyId: null,
      destinationCompanyId: null,
      itineraryVisit: {},
      handlingReq: {},
      exspansionOpen: false,
      transportTypes: ["TRU", "TRA", "VES", "INT"],
      saveVoyageId: null,
      saveTransportType: null,
      listTrainVoyages: [],
      voyageOut: {},
      handlingOutTrainVoyage: [],
      allCompanies: [],
    };
  },
  computed: {},
  async mounted() {
    await this.fetchData();
  },
  created() {},
  methods: {
    trainVoyageChanged() {
      if (!this.handling.voyageExtId) {
        this.handling.transportType = null;
      } else {
        this.handling.transportType = "TRA";
      }
      if (this.handlingOutTrainVoyage) this.destinationLocode = this.handlingOutTrainVoyage.destinationLocode;
    },
    async fetchData() {
      try {
        this.handling = await this.$api.yardData.getHandlingById(this.handlingId);
        this.listTrainVoyages = await this.$api.yardData.listTrainVoyages();
        this.visit = await this.$api.yardData.getVisitById(this.handling.visitId);
        console.log("this.visit", this.visit);
        console.log("this.handling", this.handling);
        // console.log("********this.listTrainVoyages :", this.listTrainVoyages);
        if (this.handling.primaryVisitId) {
          this.primaryVisit = await this.$api.yardData.getVisitById(this.handling.primaryVisitId);
        }
        if (this.handling.carrierVisitId) {
          this.carrierVisit = await this.$api.yardData.getVisitById(this.handling.carrierVisitId);
        }
        console.log("this.$apiConfiguration.BASE_PATH_YARD", this.$apiConfiguration.BASE_PATH_YARD);
        this.locodes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "locodes/list");
        // console.log("locodes", this.locodes);
        this.itineraryVisit = await this.$api.yardData.getItineraryByVisitId(this.handling.visitId);

        if (this.itineraryVisit) {
          this.departureLocode = this.itineraryVisit.departureLocode;
          this.departureCompanyId = this.itineraryVisit.departureCompanyId;
          this.departureCompany = this.itineraryVisit.departureCompany;
          this.destinationLocode = this.itineraryVisit.destinationLocode;
          this.destinationCompanyId = this.itineraryVisit.destinationCompanyId;
          this.destinationCompany = this.itineraryVisit.destinationCompany;
          this.podLocode = this.itineraryVisit.podLocode;
          this.spodLocode = this.itineraryVisit.spodLocode;
          this.polLocode = this.itineraryVisit.polLocode;
        } else {
          this.itineraryVisit = new Object();
        }

        if (this.handling && this.handling.direction === "OUT") {
          this.saveVoyageId = this.handling.voyageExtId;
          this.saveTransportType = this.handling.transportType;
        }


        if (this.handling.voyageExtId) {
          this.handlingOutTrainVoyage = this.listTrainVoyages.find(x => x.id == this.handling.voyageExtId);
        }
        this.allCompanies=await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/all");
      } catch (e) {
        console.log(e);
      }
    },
    onExpansionPanelClick() {
      if (this.editMode) {
        this.editMode = false;
      } else {
        this.editMode = true;
      }
    },
    onExpansionOpen(event) {
      if (event.currentTarget.classList.contains("v-expansion-panel-header--active")) {
        console.log("Panel is closing/now closed.");
        this.exspansionOpen = false;
      } else {
        console.log("Panel is opening/now open.");
        this.exspansionOpen = true;
      }
    },
    async saveChanges() {
      this.itineraryVisit.visitId = this.visit.id;
      this.itineraryVisit.departureLocode = this.departureLocode;
      this.itineraryVisit.departureCompanyId = this.departureCompanyId;
      this.itineraryVisit.destinationLocode = this.destinationLocode;
      this.itineraryVisit.destinationCompanyId = this.destinationCompanyId;
      this.itineraryVisit.podLocode = this.podLocode;
      this.itineraryVisit.spodLocode = this.spodLocode;
      this.itineraryVisit.polLocode = this.polLocode;
      var itinerary = await this.$api.yardData.updateItineraryVisit(this.itineraryVisit);



      if (this.handling.direction === "OUT" && this.handlingOutTrainVoyage && (this.saveVoyageId !== this.handlingOutTrainVoyage.id || this.saveTransportType !== this.handling.transportType)) {
        this.handling.voyageOldExtId = this.saveVoyageId;

        var viaggioSelected = this._.find(this.listTrainVoyages, v => v.id === this.handlingOutTrainVoyage.id);

        this.handling.voyageCode = viaggioSelected.code + " - " + (viaggioSelected.etd ? moment(viaggioSelected.etd).format("DD/MM/YYYY HH:mm") : "");
        //" - " + viaggioSelected.destination;
        this.handling.voyageExtId = viaggioSelected.id;
      } else if (this.handling.direction === "OUT" && !this.handlingOutTrainVoyage ) {
         this.handling.voyageCode = null;
         this.handling.voyageExtId = null;
         this.handling.voyageOldExtId = this.saveVoyageId;
      }
      

      var handlingUpdated = await this.$api.yardData.updateHandlings(this.handling);
      console.log("handlingUpdated", handlingUpdated);

      await this.fetchData();
      this.editMode = false;
    },
    async discardChanges() {
      console.log("discard handling changes", this.handling);
      await this.fetchData();
      this.editMode = false;
    },

    voyageCodeRender: item => item.code + " - " + (item.etd ? moment(item.etd).format("DD/MM/YYYY HH:mm") : "") + " - " + item.destination,
    voyageIdValue: item => item.id,
  },
  filters: {
    formatDate(date) {
      if (!date) return "N/A";
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateTime(date) {
      if (!date) return "N/A";
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
  mixins: [YardEnumMixin],
  components: {
    EnumVisualization,
    LabeledContent,
    DateTimeAdapter,
    UnitVisitViewPanelLite,
    GoodsVisitViewPanelLite,
    RoroVisitViewPanelLite,
    PersonVisitViewPanelLite,
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
