<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row align="center">
      <v-col cols="12">
        <v-card>
          <v-app-bar height="30" flat color="warning">
            <span class="white--text text-uppercase text-subtitle-1">{{ tLabel("Damages") }}</span>
          </v-app-bar>
          <v-card-text>
            <v-container fluid v-if="editMode === true">
              <v-row>
                <v-col cols="5">
                  <v-autocomplete
                    :label="tLabelRequired('Damage Type')"
                    :items="damageTypes"
                    clearable
                    v-model="damageToAdd.damageType"
                    :item-text="damageTypeRender"
                    return-object
                  />
                </v-col>
                <v-col cols="5">
                  <v-text-field :label="tLabel('Position')" v-model="damageToAdd.position" @input="(val) => (damageToAdd.position = val ? val.toUpperCase() : null)"/>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="10">
                  <v-textarea :label="tLabel('Remarks')" v-model="damageToAdd.remarks" counter maxlength="255" />
                </v-col>
                <v-col cols="2" class="text-center">
                  <v-btn color="primary" @click="addDamage"><v-icon>mdi-plus</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid>
              <v-row align="center">
                <v-col cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead class="header">
                        <tr>
                          <th v-if="editMode === true" />
                          <th class="text-left">{{ tLabel("Type") }}</th>
                          <th class="text-left">{{ tLabel("Position") }}</th>
                          <th class="text-left">{{ tLabel("Remarks") }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="damages && damages.length > 0">
                        <tr v-for="damage in damages" :key="damage.damageType.id + damage.position">
                          <td v-if="editMode === true">
                            <v-btn icon color="primary" @click="removeDamage(damage)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </td>
                          <td>{{ damage.damageType.code }} - {{ damage.damageType.description }}</td>
                          <td>{{ damage.position }}</td>
                          <td>{{ damage.remarks }}</td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="editMode === true ? 4 : 3">{{ tLabel("No damages") }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "VisitDamagesViewPanel",
  props: {
    damages: {
      type: Array,
      required: true
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      damageTypes: [],
      damageToAdd: {},     
    };
  },
  computed: {},
  watch: {
    visit: async function () {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.damageTypes = await this.$api.yardData.damageTypes();
        console.log("damage types", this.damageTypes);
      } catch (e) {
        console.log(e);
      }
    },
    damageTypeRender: item => item.code + ' - ' + item.description,
    addDamage() {
      this.damages.push(JSON.parse(JSON.stringify(this.damageToAdd)));
      console.log("damages", this.damages);
      this.damageToAdd = {};
    },
    removeDamage(damage) {
      this.damages.splice(this.damages.indexOf(damage),1);
      console.log("damages", this.damages);
    },
  },
  mixins: [],
  components: {},
};
</script>

<style scoped></style>
