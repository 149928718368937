<template>
  <v-card>
    <v-card-title class="text-uppercase font-weight-light grey lighten-5">
      <v-icon large class="mr-2">mdi-dolly</v-icon>{{ tLabel("Goods") }}
      <v-spacer />
      <v-btn depressed :outlined="page!=='INFO'" color="primary" class="mx-2" @click="page='INFO'">
        <v-icon class="mr-2">mdi-information-outline</v-icon>{{ tLabel("Info") }}
      </v-btn>
      <v-btn depressed :outlined="page!=='ITINERARY'" color="primary" class="mx-2" @click="page='ITINERARY'">
        <v-icon class="mr-2">mdi-map-marker-path</v-icon>{{ tLabel("Departure") + " / " + tLabel("Destination") }}
      </v-btn>
      <v-btn color="primary" class="mx-2" v-if="!editMode" @click="editVisit"><v-icon>mdi-pencil</v-icon></v-btn>
      <v-btn outlined class="mx-2" v-if="editMode" @click="discardChanges"><v-icon>mdi-close</v-icon></v-btn>
      <v-btn color="success" class="mx-2" v-if="editMode" @click="saveChanges"><v-icon>mdi-content-save</v-icon></v-btn>
    </v-card-title>
    <v-card-text v-show="page === 'INFO'">
      <v-form ref="editForm">
        <v-container v-if="visit">
          <!-- <v-row align="center">
              <v-col cols="12">
                {{visit}}       
              </v-col>
            </v-row> -->
          <!-- <v-row align="center">
              <v-col cols="12">
                <GoodsVisitViewPanelLite :visit="visit"/>
              </v-col>
            </v-row> -->
          <v-row align="center">
            <v-col cols="3">
              <LabeledContent v-if="!editMode" :value="visit.goods" :label="tLabel('Goods')" />
              <v-text-field height="75" v-else :label="tLabel('Goods')" v-model="visit.goods" background-color="header" />
            </v-col>
            <v-col cols="3">
              <LabeledContent :value="visit.goodsTypeCode" :label="tLabel('Goods Type')" />
            </v-col>
            <v-col cols="3">
              <LabeledContent v-if="!editMode" :value="visit.objectCode" :label="tLabel('Unit Code')" />
              <v-text-field height="75" v-else :label="tLabel('Unit Code')" v-model="visit.objectCode" background-color="header" @input="(val) => (visit.objectCode = val ? val.toUpperCase() : null)"/>
            </v-col>
            <v-col cols="3">
              <LabeledContent v-if="!editMode" :value="visit.lot" :label="tLabel('Lot')" />
              <v-text-field height="75" v-else :label="tLabel('Lot')" v-model="visit.lot" background-color="header" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent :label="tLabel('Count Type')">
                <template v-slot:content>
                  <EnumVisualization :value="visit.countType" :enums="countTypes" :enumsMap="countTypesMap" />
                </template>
              </LabeledContent>
            </v-col>
            <v-col cols="4">
              <LabeledContent v-if="!editMode" :value="visit.owner" :label="tLabel('Owner')" />
              <v-autocomplete height="75" v-else :label="tLabelRequired('Owner')" v-model="visit.ownerCompanyId" background-color="header"  :items="owners" item-value="id" item-text="code"/>
            </v-col>
            <v-col cols="4">
              <LabeledContent :label="tLabel('Customs Status')">
                <template v-slot:content>
                  <EnumVisualization :value="visit.customsStatus" :enums="customsStatus" :enumsMap="customsStatusMap" />
                </template>
              </LabeledContent>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <LabeledContent :label="tLabel('Status')">
                <template v-slot:content>
                  <EnumVisualization :value="visit.status" :enums="visitStatus" :enumsMap="visitStatusMap" />
                </template>
              </LabeledContent>
            </v-col>
            <v-col cols="4">
              <LabeledContent :label="tLabel('Position Status')">
                <template v-slot:content>
                  <EnumVisualization :value="visit.positionStatus" :enums="positionStatus" :enumsMap="positionStatusMap" />
                </template>
              </LabeledContent>
            </v-col>
            <v-col cols="4" v-if="visit.positionVisitId">
              <LabeledContent :value="visit.positionVisitObjectCode" :label="tLabel('Position')">
                <template #content="slotProps">
                  <v-chip label color="primary" @click="goToVisitDetails(visit.positionVisitId, visit.positionVisitSysType)">
                    <span class="white--text text-h6">
                      {{ slotProps.data }}
                    </span>
                  </v-chip>
                </template>
              </LabeledContent>
            </v-col>
            <v-col cols="4" v-else>
              <v-text-field height="75" v-if="editMode && visit.positionType==='Y'" :label="tLabel('Position')" v-model="visit.positionCode" background-color="header" @input="(val) => (visit.positionCode = val ? val.toUpperCase() : null)"/>
              <LabeledContent v-else :value="visit.positionCode" :label="tLabel('Position')" />        
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col cols="12">
              <LabeledContent v-if="!editMode" :value="visit.description" :label="tLabel('Description')" />
              <v-text-field height="75" v-else :label="tLabel('Description')" v-model="visit.description" background-color="header" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3">
              <LabeledContent :label="tLabel('Quantity')" :value="visit.countType==='L'?visit.qty:visit.qty+'/'+visit.qtyInitial" />
            </v-col>
            <v-col cols="3">
              <LabeledContent  v-if="!editMode" :label="tLabel('Gross weight')" :numericValue="visit.grossweight" />
                <v-text-field height="75" v-else :label="tLabel('Gross weight')" v-model="visit.grossweight" background-color="header" />
            </v-col>
            <v-col cols="3">
              <LabeledContent v-if="!editMode" :label="tLabel('Dangerous')">
                <template v-slot:content>
                  <div><v-icon v-if="visit.dangerous === true" color="warning">mdi-alert</v-icon></div>
                </template>
              </LabeledContent>
              <v-checkbox height="75" v-else :label="tLabel('Dangerous')" v-model="visit.dangerous" background-color="header" />    
            </v-col>
            <v-col cols="3">
              <LabeledContent v-if="!editMode" :label="tLabel('Cycle')" :value="visit.cycle">
              </LabeledContent>
              <v-select v-else v-model="visit.cycle" :items="cyclesList" :label="tLabel('Cycle')" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <LabeledContent v-if="!editMode" :label="tLabel('Remarks')" :value="visit.remarks" />
              <v-textarea v-else :label="tLabel('Remarks')" v-model="visit.remarks" counter maxlength="255" background-color="header" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <VisitPrimaryViewPanel :visit="visit" />
            </v-col>
          </v-row>
          <v-row v-if="visit.damages">
            <v-col cols="12">
              <VisitDamagesViewPanel :damages="visit.damages" :editMode="editMode" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-text v-show="page === 'ITINERARY'">
      <ItineraryForm dense :itineraryVisit="visit.itineraryVisit" :editable="editMode" />
    </v-card-text>
  </v-card>
</template>

<script>
import YardEnumMixin from "../../mixins/yard/EnumMixin";
import FormatsMixins from "../../mixins/FormatsMixins";
import EnumVisualization from "@/components/yard/EnumVisualization.vue";
import LabeledContent from "@/components/yard/LabeledContent.vue";
// import GoodsVisitViewPanelLite from '@/components/yard/GoodsVisitViewPanelLite';
import VisitPrimaryViewPanel from "@/components/yard/VisitPrimaryViewPanel";
import VisitDamagesViewPanel from "@/components/yard/VisitDamagesViewPanel";
import ItineraryForm from "@/components/yard/ItineraryForm";

export default {
  name: "GoodsVisitViewPanel",
  props: {
    visitId: {
      required: true,
    },
  },
  data() {
    return {      
      page: "INFO",
      visit: {},
      owners: [],
      editMode: false,
    };
  },
  emits: ["visitEditStatusChanged"],
  computed: {},
  async mounted() {
    await this.fetchData();
  },
  watch: {
    visitId: async function () {
      await this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      try {
        this.visit = await this.$api.yardData.getVisitById(this.visitId);
        if (!this.visit.itineraryVisit)
          this.visit.itineraryVisit = {
            departure: null,
            departureLocode: null,
            departureCompanyId: null,
            destination: null,
            destinationLocode: null,
            destinationCompanyId: null,
            podLocode: null,
            spodLocode: null,
            polLocode: null,
            visitId: this.visitId
          }
        this.owners= await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/owners");
      } catch (e) {
        console.log(e);
      }
    },
    editVisit() {
      this.$emit("visitEditStatusChanged", this.visit.id, "IN_PROGRESS");
      this.editMode = true;
    },
    async saveChanges() {
      const valid = this.$refs.editForm.validate();
      if (!valid) {
        return;
      }
      console.log("save visit changes", this.visit);
      var ret = await this.$api.yardData.updateVisitWithDamages(this.visit, "GOO");
      console.log("save visit changes ret ", ret);
      this.$emit("visitEditStatusChanged", this.visit.id, "SAVED");
      await this.fetchData();
      this.editMode = false;
    },
    async discardChanges() {
      console.log("discard visit changes", this.visit);
      this.$emit("visitEditStatusChanged", this.visit.id, "DISCARDED");
      await this.fetchData();
      this.editMode = false;
    },
  },
  mixins: [YardEnumMixin, FormatsMixins],
  components: {
    EnumVisualization,
    LabeledContent,
    // GoodsVisitViewPanelLite
    VisitPrimaryViewPanel,
    VisitDamagesViewPanel,
    ItineraryForm
  },
};
</script>
